@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 200;
    src: url(assets/fonts/SourceSansPro-ExtraLight.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 200;
    src: url(assets/fonts/SourceSansPro-ExtraLightItalic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 300;
    src: url(assets/fonts/SourceSansPro-Light.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 300;
    src: url(assets/fonts/SourceSansPro-LightItalic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 400;
    src: url(assets/fonts/SourceSansPro-Regular.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 400;
    src: url(assets/fonts/SourceSansPro-Italic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 600;
    src: url(assets/fonts/SourceSansPro-SemiBold.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 600;
    src: url(assets/fonts/SourceSansPro-SemiBoldItalic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 700;
    src: url(assets/fonts/SourceSansPro-Bold.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 700;
    src: url(assets/fonts/SourceSansPro-BoldItalic.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: normal;
    font-weight: 900;
    src: url(assets/fonts/SourceSansPro-Black.ttf) format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: SourceSansPro;
    font-style: italic;
    font-weight: 900;
    src: url(assets/fonts/SourceSansPro-BlackItalic.ttf) format("truetype");
    font-display: swap;
  }
}

.section {
  @apply max-w-screen-xl mx-auto px-4 py-6 md:py-8;
}

.section-title {
  @apply font-sans text-xl md:text-2xl text-primary-90 tracking-widest;
}

.section-text {
  @apply font-sans text-base md:text-lg text-black-80;
}

.link-text {
  @apply font-sans text-base md:text-lg text-primary-90 hover:text-gray-900;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-list > div {
  margin-left:0;
}